<!-- 
The primary page to search for specific accounts.
-->


<template>
    <v-container v-if="$store.state.user">
        <!-- Page Title -->
        <v-row class="text-center mt-10">
            <v-col class="mb-4">
                <h1 class="display-2 font-weight-bold mb-3">Search<v-icon large>mdi-magnify</v-icon></h1>
                <p>Please select your desired search & enter the appropriate information.</p>
            </v-col> 
        </v-row>
        <v-row align="center">
            <v-col class="col-4"></v-col>
            <v-col>
                <v-select :items="items" v-model="searchChoice" label="Desired Search" solo></v-select>
            </v-col>
            <v-col></v-col>
        </v-row>

        <!-- Search bar for text input (FULL NAME) -->
        <div v-if="searchChoice =='Person'">
            <v-card class="mx-auto my-12 text-center" max-width="674">
                <template slot="progress">
                    <v-progress-linear color="deep-purple" height="55" indeterminate></v-progress-linear>
                </template>

                <v-card-text>
                    <v-row align="center" class="mx-0"></v-row>
                    <div class="my-4 text-subtitle-1"></div>
                    <div>
                        <p>NOTE: Please do not include any spaces at the end of the first or last name, as these will cause the search to fail.</p>
                    </div>
                </v-card-text>

                <v-card-text>
                    <v-text-field required v-model="firstName" label="First Name" solo></v-text-field>
                    <v-text-field required v-model="lastName" label="Last Name" solo></v-text-field>
                </v-card-text>

                <v-card-actions>
                    <v-col>
                        <v-btn color="primary" @click="this.search">Search</v-btn>
                    </v-col>
                </v-card-actions>
            </v-card>
        </div>

        <!-- Search bar for text input (ASSOCIATION) -->
        <div v-if="searchChoice =='Association'">
            <v-card class="mx-auto my-12 text-center" max-width="674">
                <template slot="progress">
                    <v-progress-linear color="deep-purple" height="55" indeterminate></v-progress-linear>
                </template>

                <v-card-text>
                    <v-row align="center" class="mx-0"></v-row>
                    <div class="my-4 text-subtitle-1"></div>
                    <div>
                        <p>NOTE: Please enter the name of the desired association.</p>
                    </div>
                </v-card-text>

                <v-card-text>
                    <v-text-field required v-model="associationSearched" label="Association Name" solo></v-text-field>
                </v-card-text>

                <v-card-actions>
                    <v-col>
                        <v-btn color="primary" @click="this.searchAssociation">Search</v-btn>
                    </v-col>
                </v-card-actions>
            </v-card>
        </div>

        <!-- Snackbar for failed search -->
        <div class="text-center ma-2">
            <v-snackbar v-model="snackbar" shaped>
                {{ text }}

                <template v-slot:action="{ attrs }">
                    <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
                </template>
            </v-snackbar>
        </div>
    </v-container>
</template>

<script>
import PeopleService from '@/services/PeopleService'
import AssociationsService from '../services/AssociationsService';

export default {
    name: 'personSearch',
    data () {
        return {
            firstName: "",
            lastName: "",
            associd: 3,
            person: null,
            personID: null,
            items: ['Person', 'Association'],
            searchChoice: null,
            associationSearched: "",
            snackbar: false,
            text: `Insufficient details -- Please try again.`
        }
    },

    methods: {

        // Search database with provided first and last name
        async search() {

            // If space in first or last char of string, remove it
            this.firstName = this.firstName.trim();
            this.lastName = this.lastName.trim();

            // search for single person -- extract person's ID and route to ID's details page
            this.person = await PeopleService.searchPersonByName({params: {first_name: this.firstName, last_name: this.lastName}});
            if (this.person.data.id) {
                this.personID = this.person.data.id;
                this.$router.push({ path: `associations/peopleDetails/${this.personID}`});
            }
            else { 
                // Reset form input and display failed search snackbar
                this.firstName = "";
                this.lastName = "";
                this.snackbar = true; 
            }
        },

        // Search database with provided first and last name
        async searchAssociation() {

            // If space in first or last char of string, remove it
            this.associationSearched = this.associationSearched.trim();

            this.association = await AssociationsService.searchAssociationByName({params: {name: this.associationSearched}});
            
            // search for single Association -- extract Association's ID and route to ID's details page
            if (this.association.data.id) {
                this.associationID = this.association.data.id;
                this.$router.push({ path: `associations/associationsView/${this.associationID}`});
            }
            else { 
                // Reset form input and display failed search snackbar
                this.associationSearched = "";
                this.snackbar = true; 
            }
        },
    }

}
</script>